import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper.js';
import GUI from 'lil-gui';

// const gui = new GUI();
const canvas = document.querySelector('canvas.webgl');
const scene = new THREE.Scene();
let isAudioContextInitialized = false;

window.addEventListener('click', () => {
    if (!isAudioContextInitialized) {
        const audioContext = THREE.AudioContext.getContext();
        if (audioContext.state === 'suspended') audioContext.resume().then(() => console.log('AudioContext resumed'));
        isAudioContextInitialized = true;
    }
});

const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
scene.add(camera);

const listener = new THREE.AudioListener();
camera.add(listener);

const audioLoader = new THREE.AudioLoader();
const sounds = [];
const models = [];
const analyzers = [];

const centerPosition = new THREE.Vector3(0, 0, 0); 


const frustum = new THREE.Frustum();
const matrix = new THREE.Matrix4();
for (let i = 0; i < 24; i++) {
    const sound = new THREE.PositionalAudio(listener);
    audioLoader.load(`minhyung/sound${i + 1}.mp3`, buffer => {
        sound.setBuffer(buffer)
            .setLoop(true)
            .setRefDistance(2)
            .setMaxDistance(10)
            .setDirectionalCone(60, 120, 0.1) 
            .setVolume(1)
            .play();
        analyzers.push(new THREE.AudioAnalyser(sound, 64));
    });
    sounds.push(sound);
    
    const model = new THREE.Mesh(
        new THREE.SphereGeometry(0.5, 32, 32),
        new THREE.MeshBasicMaterial({ 
            color: new THREE.Color(`hsl(${i * 15}, 100%, 50%)`),
            wireframe: false 
        })
    );


    const angle = Math.random() * Math.PI * 2; 
    const radius = 3;  
    // const radius = 3 + Math.random() * 0.01;  
    const zOffset = (Math.random() - 0.5) * 2; 

    model.position.set(
        Math.cos(angle) * radius, 
        Math.sin(angle) * radius, 
        zOffset
    );

    model.lookAt(centerPosition); 
    
    model.add(sound);
    model.add(new PositionalAudioHelper(sound));
    scene.add(model);
    models.push(model);
}

const torusGeometry = new THREE.TorusGeometry(5.7,3, 100,150 ); 
const torusMaterial = new THREE.ShaderMaterial({
    vertexShader: `
        varying vec3 vNormal;
        varying vec3 vPosition;

        void main() {
            vNormal = normalize(normalMatrix * normal);
            vPosition = (modelViewMatrix * vec4(position, 1.0)).xyz;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        varying vec3 vNormal;
        varying vec3 vPosition;

        void main() {
            vec3 viewDir = normalize(-vPosition); // Direction to the viewer (camera)
            vec3 lightPos = vec3(10.0, 10.0, 10.0); // Light position
            vec3 lightDir = normalize(lightPos - vPosition); // Direction to the light source
            
            // Calculate reflection direction
            vec3 reflectDir = reflect(-lightDir, vNormal);

            // Base metallic color
            vec3 baseColor = vec3(0.8, 0.8, 0.85); // Slightly blue-tinted metal
            
            // Ambient component
            vec3 ambient = 0.1 * baseColor;

            // Diffuse shading
            float diffuseStrength = max(dot(vNormal, lightDir), 0.0);
            vec3 diffuse = diffuseStrength * baseColor;

            // Specular highlight
            float specularStrength = pow(max(dot(viewDir, reflectDir), 0.0), 64.0); // Adjust shininess with the power
            vec3 specular = specularStrength * vec3(1.0, 1.0, 1.0); // White specular highlights

            // Combine components
            vec3 finalColor = ambient + diffuse + specular;

            gl_FragColor = vec4(finalColor, 1.0);
        }
    `,
    uniforms: {}
});



const torus = new THREE.Mesh(torusGeometry, torusMaterial);
scene.add(torus); 


function checkIfModelIsVisible(model) {

    model.updateMatrixWorld();
    const modelPosition = model.getWorldPosition(new THREE.Vector3());


    matrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);  
    frustum.setFromProjectionMatrix(matrix);


    return frustum.containsPoint(modelPosition);
}


function updateSoundBasedOnVisibility() {
    models.forEach((model, index) => {
        const sound = sounds[index];
        if (checkIfModelIsVisible(model)) {
            sound.setVolume(1); 
        } else {
            sound.setVolume(0);
        }
    });
}

const pointLight = new THREE.PointLight(0xffffff, 1, 100);
pointLight.position.set(10, 10, 10);
scene.add(pointLight);

const ambientLight = new THREE.AmbientLight(0x404040); 
scene.add(ambientLight);

const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
directionalLight.position.set(1, 5, 5).normalize();  
scene.add(directionalLight);

camera.position.set(0.0001, 0, 0);
camera.lookAt(0, 0, 0);
camera.updateProjectionMatrix();

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});


const controls = new OrbitControls(camera, canvas);
controls.enabled = true;
controls.minDistance = 0.00001; 
controls.maxDistance = 100; 
controls.update(); 

const renderer = new THREE.WebGLRenderer({ canvas });
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const clock = new THREE.Clock();

function rotateCamera(elapsedTime) {

    const rotationSpeed = 0.01;
    camera.rotateX(rotationSpeed * 0.01);
}

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    models.forEach((model, index) => {
        const analyser = analyzers[index];
        if (analyser) {
            const frequencyData = analyser.getFrequencyData();
            const scale = 1 + (frequencyData.reduce((sum, value) => sum + value, 0) / frequencyData.length / 256) * 0.5;
            model.scale.set(scale, scale, scale);
        }
    });

        updateSoundBasedOnVisibility();

    rotateCamera(elapsedTime);
    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
};

tick();
